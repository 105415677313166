import { PreviewType, WebstoreTheme, WebstoreConfigurationStep, WebstoreBusinessDay, WebstoreFulfilment } from "@/enums"
import { WebstoreConfig, WebstorePolicy, WebstoreShipping } from "@/types"
import { atom } from "jotai"
import { focusAtom } from "jotai-optics"

/**
 * Mode determines whether some components are editable, the appearance of the editor,
 * whether certain functionlaties are available, etc.
 */
export const builderModeEnabledAtom = atom(false)

const openTime = new Date("01-01-2025") // fix default so draft vs live comparison is consistent
const closeTime = new Date(openTime)
closeTime.setDate(closeTime.getDate() + 1)

const defaultShipping: WebstoreShipping = {
	fulfilmentProcess: WebstoreFulfilment.PICKUP,
	pickupInstructions: "",
	deliveryFee: 0, // in cents
}

const defaultWebstorePolicy: WebstorePolicy = {
	acceptedAt: null,
	storePolicies: {
		paiaManual: false,
		privacyPolicy: false,
		returnsRefundsPolicy: false,
		deliveryPolicy: false,
	},
	merchant: {
		name: "",
		email: "",
		address: "",
		contact: "",
		companyName: "",
		companyRegistrationNumber: "",
		webstoreName: "",
	},
}

/**
 * The initial state of the webstore configuration.
 * This value should never really be used, as it is only used to initialize the webstore configuration.
 * This should always be overridden by the actual webstore configuration after fetching it.
 */
export const initialWebconfigDefaults: WebstoreConfig = {
	currentStep: WebstoreConfigurationStep.AESTHETIC_SELECTION,
	aboutUs: "",
	contact: {
		address: "",
		email: "",
		phone: "",
		whatsapp: "",
	},
	colorScheme: WebstoreTheme.DefaultTheme,
	hero: {
		header: "",
		subheading: "",
		imageUrl: "",
	},
	tradingHours: [],
	shipping: defaultShipping,
	legalWebstorePolicyTnCs: defaultWebstorePolicy,
}
/**
 * The webstore configuration for the builder i.e. editing capabilities.
 * This config should only facilitate the builder experience.
 * DO NOT use this for the public site.
 */
export const builderWebconfigStateAtom = atom<WebstoreConfig>({ ...initialWebconfigDefaults })

export const webstoreNameAtom = atom("")

export const previewTypeAtom = atom<PreviewType>(PreviewType.Desktop)

export const colourThemeAtom = focusAtom(builderWebconfigStateAtom, (optic) => optic.prop("colorScheme").optional())

export const fullRefreshAtom = atom<boolean>(true) // default to true to force a refresh on first load

// a bit of hack here - ideally using the touched state from formControlAtom
// other components could take advantage of the fact that a form was being used to update
export const touchedAtom = atom<boolean>(false)

/**
 * Manages the state used to display a modal containing media links (Email/WhatsApp)
 * and the downloading of a QR Code linking to the webstore
 */
const publishedModalUIStateAtom = atom({
	openPublishedModal: false,
	openDownloadQRCodeModal: false,
	downloadQRCodeSuccess: false,
	publishBlocker: false,
})

export const openPublishedModalAtom = focusAtom(publishedModalUIStateAtom, (optic) => optic.prop("openPublishedModal"))

export const openDownloadQRCodeModalAtom = focusAtom(publishedModalUIStateAtom, (optic) => optic.prop("openDownloadQRCodeModal"))
export const downloadQRCodeSuccess = focusAtom(publishedModalUIStateAtom, (optic) => optic.prop("downloadQRCodeSuccess"))

export const publishBlockerAtom = focusAtom(publishedModalUIStateAtom, (optic) => optic.prop("publishBlocker"))
