import { Address } from "@/types"

/**
 * Formats an address object into a string.
 *
 * @param {Address} [address] - The address object to format.
 * @returns {string} The formatted address string.
 *
 * @example
 * const address = {
 *   buildingComplex: "",
 *   streetAddress: "123 Main St",
 *   city: "Cape Town",
 *   province: "WC",
 *   postalCode: "12345"
 * };
 * const formattedAddress = displayAddress(address);
 * console.log(formattedAddress); // "123 Main St, Cape Town, WC, 12345"
 */
export const displayAddress = (address?: Address): string => {
	if (!address || typeof address !== "object") return ""

	const toSentenceCase = (str?: string) => {
		if (!str) return str
		return str
			.split(" ")
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
			.join(" ")
	}

	const parts = [
		address.complexBuilding,
		address.street1,
		address.street2,
		address.suburb,
		address.city,
		address.province,
		address.code,
	]
		.filter(Boolean)
		.map(toSentenceCase)

	return parts.join(", ")
}
