import mixpanel from "mixpanel-browser"

export interface IButtonClickEventAttributes {
	button_text: string
	button_url?: string
	page_title: string
	page_url: string
	popup_title?: string
}

export interface IPageViewEventAttributes {
	page_title: string
	page_url: string
	page_referrer?: string
}

export interface IPopupViewEventAttributes {
	popup_title: string
	popup_description: string
	page_title: string
	page_url: string
}

export interface ILinkClickEventAttributes {
	link_label: string
	link_url?: string
	page_title: string
	page_url: string
}

export enum mxPanelEventNames {
	BUTTON_CLICK = "Button Click",
	LINK_CLICK = "Link Click",
	POPUP_VIEW = "Pop-up View",
}

export const logButtonClickEvent = (
	buttonClickAttributes: IButtonClickEventAttributes,
	appendBaseUrl: boolean = true,
	toggleOn: boolean = true,
): void => {
	if (toggleOn) {
		const domainName = appendBaseUrl
			? `https://${process.env.WEBSITE_SUB_DOMAIN_NAME ?? "webstore"}.${process.env.WEBSITE_ROOT_DOMAIN_NAME ?? "ikhokha.com"}${buttonClickAttributes.button_url}`
			: buttonClickAttributes.button_url
		const updatedButtonClickAttributes = {
			...buttonClickAttributes,
			button_url: domainName,
		}
		console.debug("Mixpanel: logButtonClickEvent", updatedButtonClickAttributes)
		mixpanel.track(mxPanelEventNames.BUTTON_CLICK, {
			...updatedButtonClickAttributes,
		})
	}
}

export const logPopupViewEvent = (popupViewAttributes: IPopupViewEventAttributes, toggleOn: boolean = true): void => {
	if (toggleOn) {
		console.debug("Mixpanel: logPopupViewEvent", popupViewAttributes)
		mixpanel.track(mxPanelEventNames.POPUP_VIEW, popupViewAttributes)
	}
}

export const logLinkClickEvent = (linkClickAttributes: ILinkClickEventAttributes, toggleOn: boolean = true): void => {
	if (toggleOn) {
		console.debug("Mixpanel: logLinkClickEvent", linkClickAttributes)
		mixpanel.track(mxPanelEventNames.LINK_CLICK, linkClickAttributes)
	}
}

export const logPageViewEvent = (pageViewEventAttributes: IPageViewEventAttributes, toggleOn: boolean = true): void => {
	if (toggleOn) {
		console.debug("Mixpanel: logPageViewEvent", pageViewEventAttributes)
		mixpanel.track_pageview(pageViewEventAttributes)
	}
}
