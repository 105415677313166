import { TableRows } from "@/components/catalogue/table/CatalogueTable"
import { CardProps } from "@/types"
import { defaultCatalogueType, defaultCategory } from "@/utils"
import { filterCardsFn, sortByOptions } from "@/utils/manageCatalogue"
import { atom } from "jotai"
import { focusAtom } from "jotai-optics"

export type CatalogueTableState = {
	/**
	 * Rows array
	 */
	rows: TableRows
	/**
	 * Total number of products (including its variants) or services.
	 * Independent value of rows
	 */
	totalItemCount: number
	/**
	 * Array of product IDs or service IDs. Used by the list component
	 * to show which items have been selected
	 */
	checked: string[]
	/**
	 * Function to update selected items in the list component
	 * @param values an array of product IDs or service IDs
	 */
	setChecked: (values: string[]) => void
	/**
	 * Search term used to filter visible items in the list component
	 */
	searchTerm: string
}

const initialState: CatalogueTableState = {
	rows: [],
	totalItemCount: 0,
	checked: [],
	setChecked: (values: string[]) => [],
	searchTerm: "",
}

export const productsListStateAtom = atom<CatalogueTableState>({ ...initialState })

export const servicesListStateAtom = atom<CatalogueTableState>({ ...initialState })

export const productsCheckedAtom = focusAtom(productsListStateAtom, (optic) => optic.prop("checked"))

export const servicesCheckedAtom = focusAtom(servicesListStateAtom, (optic) => optic.prop("checked"))

export const productsSearchTermAtom = focusAtom(productsListStateAtom, (optic) => optic.prop("searchTerm"))

export const servicesSearchTermAtom = focusAtom(servicesListStateAtom, (optic) => optic.prop("searchTerm"))

/**
 * State representing the currently selected tab for the catalogue selection
 * 0 refers to products tab
 * 1 refers to services tab
 */
export const selectedTabAtom = atom(0)

/**
 * Manages loading state for when a merchant is being redirected to create
 * a catalogue item
 */
export const createCatalogueRedirectPending = atom(false)

/**
 * Used to display the selected item count in the footer of the table
 */
export const itemSelectionCountAtom = atom((get) => {
	const productChecked = get(productsCheckedAtom)
	const servicesChecked = get(servicesCheckedAtom)

	const productIDs = new Set()

	const productsCount = productChecked.reduce((acc, curr) => {
		const [productID] = curr.split(".")

		if (!productIDs.has(productID)) {
			productIDs.add(productID)
			return acc + 1
		}

		return acc
	}, 0)
	const servicesCount = servicesChecked.length

	return productsCount + servicesCount
})

export const cardsCacheAtom = atom<CardProps[]>([])

/**
 * Manages the filtered state of the currently displayed catalogue
 */
export const catalogueFilterAtom = atom({
	selectedType: defaultCatalogueType,
	canFilterType: false,
	selectedCategory: defaultCategory,
	query: "",
})

export const selectedTypeAtom = focusAtom(catalogueFilterAtom, (optic) => optic.prop("selectedType"))

export const canFilterTypeAtom = focusAtom(catalogueFilterAtom, (optic) => optic.prop("canFilterType"))

export const selectedCategoryAtom = focusAtom(catalogueFilterAtom, (optic) => optic.prop("selectedCategory"))

export const cardsQueryAtom = focusAtom(catalogueFilterAtom, (optic) => optic.prop("query"))

/**
 * Manages the sorted state of the currently displayed catalogue
 */
export const catalogueSortByAtom = atom(sortByOptions[0])

export const filteredCatalogueCardsAtom = atom((get) => {
	const cards = get(cardsCacheAtom)

	const selectedType = get(selectedTypeAtom)
	const selectedCategory = get(selectedCategoryAtom)

	const filterCards = filterCardsFn(selectedType, selectedCategory)
	const filteredCatalogueCards = filterCards(cards)

	return filteredCatalogueCards
})

export const sortedCardsAtom = atom((get) => {
	const filteredCards = get(filteredCatalogueCardsAtom)

	const sortBy = get(catalogueSortByAtom)
	const sortFn = sortByOptions.find((option) => option.value === sortBy.value)!.sortFn

	const sortedCatalogueCards = sortFn(filteredCards)

	return sortedCatalogueCards
})

export const searchResultsAtom = atom((get) => {
	const cards = get(cardsCacheAtom)
	const query = get(cardsQueryAtom)

	const searchedCards = cards.filter((card) => card.name.toLowerCase().includes(query.toLowerCase()))

	const sortBy = get(catalogueSortByAtom)
	const sortFn = sortByOptions.find((option) => option.value === sortBy.value)!.sortFn

	const sortedCatalogueCards = sortFn(searchedCards)

	return sortedCatalogueCards
})

const catalogueUIStateAtom = atom({
	openFilterDrawer: false,
	openSortDrawer: false,
})

export const openFilterDrawerAtom = focusAtom(catalogueUIStateAtom, (optic) => optic.prop("openFilterDrawer"))

export const openSortDrawerAtom = focusAtom(catalogueUIStateAtom, (optic) => optic.prop("openSortDrawer"))

export const filterCountAtom = atom((get) => {
	const selectedType = get(selectedTypeAtom)
	const selectedCategory = get(selectedCategoryAtom)

	if (selectedType !== defaultCatalogueType && selectedCategory !== defaultCategory) {
		return 2
	}

	if (selectedType !== defaultCatalogueType || selectedCategory !== defaultCategory) {
		return 1
	}

	return 0
})

export type SelectedItemViewDetail = CardProps & { quantity: number; webstoreName: string }

const initialSelectedViewDetailState: SelectedItemViewDetail = {
	id: "",
	name: "",
	description: "",
	category: "",
	caption: "",
	price: 0,
	variants: [],
	imageUrls: [],
	createdAt: "",
	type: "product",
	webstoreName: "",
	quantity: 0,
	isVatApplicable: false,
}

export const selectedViewDetailAtom = atom<SelectedItemViewDetail>(initialSelectedViewDetailState)

export const selectedViewDetailQuantityAtom = focusAtom(selectedViewDetailAtom, (optic) => optic.prop("quantity"))

export const businessLogoExistsAtom = atom(false)
